import React, { ReactElement } from "react";

import dynamic from "next/dynamic";

import { FooterData } from "data/footer";
import { Footer } from "studio-design-system";

const MarketingFooterContent = dynamic(() => import("./MarketingFooterContent"));

interface Props {
  data: FooterData;
  isConcise?: boolean;
}

export const MarketingFooter = ({ data, isConcise = false }: Props): ReactElement => (
  <Footer>
    <MarketingFooterContent data={data} isConcise={isConcise} />
  </Footer>
);
